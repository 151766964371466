<template>
  <div>
    <headPage />
    <!-- 沉浸体验 -->
    <div class="box1" style="overflow: hidden"></div>
    <!-- 新闻 -->
    <div class="bigbox" style="display: flex">
      <div class="box2" style="overflow: hidden">
        <div class="new1" @click="jump('/newsdetail?index=4')">
          <img
            class="img2"
            style="display: block; width: 85%"
            src="../assets/image/newsCenter/image2.jpeg"
          />
          <div class="titletop">
            <div class="title3">新闻</div>
            <div class="title2">2022-7-31</div>
          </div>
          <p class="p1">
            建E首发 | MH享家·舒适智能展厅:<br />Magic Home，当家有引力!
          </p>
        </div>
        <div
          class="new1"
          style="margin-top: 76px"
          @click="jump('/newsdetail?index=3')"
        >
          <img
            class="img2"
            style="display: block; width: 85%"
            src="../assets/image/newsCenter/image2.jpeg"
          />
          <div class="titletop">
            <div class="title3">新闻</div>
            <div class="title2">2022-7-31</div>
          </div>
          <p class="p1">
            建E首发 | MH享家·舒适智能展厅:<br />Magic Home，当家有引力!
          </p>
        </div>
      </div>

      <div class="box3" style="overflow: hidden; margin-top: 54px">
        <div class="new1" @click="jump('/newsdetail?index=2')">
          <img
            class="img2"
            style="display: block; width: 85%"
            src="../assets/image/newsCenter/image2.jpeg"
          />
          <div class="titletop">
            <div class="title3">新闻</div>
            <div class="title2">2022-7-31</div>
          </div>
          <p class="p2">
            建E首发 | MH享家·舒适智能展厅:<br />Magic Home，当家有引力!
          </p>
        </div>
        <div
          class="new1"
          style="margin-top: 39px"
          @click="jump('/newsdetail?index=1')"
        >
          <img
            class="img2"
            style="display: block; width: 85%"
            src="../assets/image/newsCenter/image2.jpeg"
          />
          <div class="titletop">
            <div class="title3">新闻</div>
            <div class="title2">2022-7-31</div>
          </div>
          <p class="p2">
            建E首发 | MH享家·舒适智能展厅:<br />Magic Home，当家有引力!
          </p>
        </div>
        <div
          class="new1"
          style="margin-top: 47px"
          @click="jump('/newsdetail?index=0')"
        >
          <img
            class="img2"
            style="display: block; width: 85%"
            src="../assets/image/newsCenter/image2.jpeg"
          />
          <div class="titletop">
            <div class="title3">新闻</div>
            <div class="title2">2022-7-31</div>
          </div>
          <p class="p2">{{ newsList.name }}<br />{{ newsList.secName }}</p>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
import newsList from "../../public/newsList.json";
export default {
  data() {
    return {
      newsList: newsList.newsList,
    };
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.box3 {
  .new1:hover {
    transform: scale(1.1);
    // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
    z-index: 9999;
  }
}
.new1 {
  cursor: pointer;
  margin-left: 20px;
}
.bigbox {
  height: 1626px;
  background-color: #fff;
}
.titletop {
  text-align: left;
}
.box1 {
  background-image: url(../assets/image/newsCenter/image1.png);
  background-size: cover;
  width: 100%;
  height: 465px;
}
.p1 {
  margin-top: 15px;
  font-size: 38px;
  text-align: left;
  font-family: "SourceHanSansCN-Medium";
  color: #000;
}
.p2 {
  font-size: 25px;
  margin-left: 4px;
  margin-top: 7px;
  text-align: left;
  font-family: "SourceHanSansCN-Medium";
  color: #000;
}
.box2 {
  width: 851px;
  margin-left: 136px;
  margin-top: 54px;
}
.title1 {
  background-color: #d1a937;
  font-size: 16px;
  color: #fff;
  padding: 5px 0;
  width: 65px;
  margin-left: -712px;
  margin-top: 18px;
  display: inline-block;
}
.title3 {
  background-color: #d1a937;
  font-size: 16px;
  color: #fff;
  padding: 5px 0;
  width: 65px;
  display: inline-block;
  margin-top: 14px;
  text-align: center;
}
.title2 {
  font-family: "SourceHanSansCN-Light";
  color: #474747;
  display: inline-block;
  font-size: 16px;
  margin-left: 12px;
}
.box3 {
  width: 471px;
  margin-top: 40px;
}
@media (max-width: 500px) {
  .bigbox {
    height: auto;
    margin-bottom: 100px;
  }
}
</style>