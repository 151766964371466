var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headPage'),_c('div',{staticClass:"box1",staticStyle:{"overflow":"hidden"}}),_c('div',{staticClass:"bigbox",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"box2",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"new1",on:{"click":function($event){return _vm.jump('/newsdetail?index=4')}}},[_c('img',{staticClass:"img2",staticStyle:{"display":"block","width":"85%"},attrs:{"src":require("../assets/image/newsCenter/image2.jpeg")}}),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"new1",staticStyle:{"margin-top":"76px"},on:{"click":function($event){return _vm.jump('/newsdetail?index=3')}}},[_c('img',{staticClass:"img2",staticStyle:{"display":"block","width":"85%"},attrs:{"src":require("../assets/image/newsCenter/image2.jpeg")}}),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"box3",staticStyle:{"overflow":"hidden","margin-top":"54px"}},[_c('div',{staticClass:"new1",on:{"click":function($event){return _vm.jump('/newsdetail?index=2')}}},[_c('img',{staticClass:"img2",staticStyle:{"display":"block","width":"85%"},attrs:{"src":require("../assets/image/newsCenter/image2.jpeg")}}),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"new1",staticStyle:{"margin-top":"39px"},on:{"click":function($event){return _vm.jump('/newsdetail?index=1')}}},[_c('img',{staticClass:"img2",staticStyle:{"display":"block","width":"85%"},attrs:{"src":require("../assets/image/newsCenter/image2.jpeg")}}),_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"new1",staticStyle:{"margin-top":"47px"},on:{"click":function($event){return _vm.jump('/newsdetail?index=0')}}},[_c('img',{staticClass:"img2",staticStyle:{"display":"block","width":"85%"},attrs:{"src":require("../assets/image/newsCenter/image2.jpeg")}}),_vm._m(8),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(_vm.newsList.name)),_c('br'),_vm._v(_vm._s(_vm.newsList.secName))])])])]),_c('footPage')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titletop"},[_c('div',{staticClass:"title3"},[_vm._v("新闻")]),_c('div',{staticClass:"title2"},[_vm._v("2022-7-31")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p1"},[_vm._v(" 建E首发 | MH享家·舒适智能展厅:"),_c('br'),_vm._v("Magic Home，当家有引力! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titletop"},[_c('div',{staticClass:"title3"},[_vm._v("新闻")]),_c('div',{staticClass:"title2"},[_vm._v("2022-7-31")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p1"},[_vm._v(" 建E首发 | MH享家·舒适智能展厅:"),_c('br'),_vm._v("Magic Home，当家有引力! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titletop"},[_c('div',{staticClass:"title3"},[_vm._v("新闻")]),_c('div',{staticClass:"title2"},[_vm._v("2022-7-31")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p2"},[_vm._v(" 建E首发 | MH享家·舒适智能展厅:"),_c('br'),_vm._v("Magic Home，当家有引力! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titletop"},[_c('div',{staticClass:"title3"},[_vm._v("新闻")]),_c('div',{staticClass:"title2"},[_vm._v("2022-7-31")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p2"},[_vm._v(" 建E首发 | MH享家·舒适智能展厅:"),_c('br'),_vm._v("Magic Home，当家有引力! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titletop"},[_c('div',{staticClass:"title3"},[_vm._v("新闻")]),_c('div',{staticClass:"title2"},[_vm._v("2022-7-31")])])
}]

export { render, staticRenderFns }